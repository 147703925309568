var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.allowedSystem.checkList != 'undefined')?_c('div',[_c('input',{attrs:{"type":"hidden","name":_vm.field},domProps:{"value":JSON.stringify(_vm.localValue)}}),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{staticClass:"ml-5 text-subtitle-1"},[_vm._v(_vm._s(_vm.language.componentLabel.allowedFunctions))])],1),_c('div',{staticClass:"row mt-n4 mr-4 mb-3"},_vm._l((_vm.allowedChunk),function(cColumns,cValue){return _c('div',{key:cValue,staticClass:"col ml-7",staticStyle:{"max-width":"200px"}},[_vm._l((cColumns),function(checkName){return [_c('checkbox',{key:Object.values(checkName).toString(),staticStyle:{"height":"30px"},attrs:{"fieldAttrInput":{
            ..._vm.fieldAttrInput,
            id:
              _vm.getFieldId(_vm.fieldAttrInput, _vm.result, _vm.field) +
              '_' +
              Object.values(checkName).toString(),
          },"fieldAttributes":{
            name: _vm.languagePack('menu_' + Object.keys(checkName).toString()),
          },"value":_vm.isSelected(Object.values(checkName).toString()),"result":_vm.result,"disabled":_vm.getdisabled(Object.keys(checkName).toString())},on:{"input":function($event){_vm.updateValue(Object.values(checkName).toString(), ...arguments)}}})]})],2)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }